
#countDown-cont {
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    color: #ffffff;
    z-index: 99;
    display: none;
}

#vidCdowntimer {
    max-width: 350px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    background-color: white;
    font-family: Montserrat-Regular, sans-serif;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-1"] {
    background: rgb(255, 0, 0);
    background: -moz-linear-gradient(45deg, rgba(255, 0, 0, 1) 0%, rgba(255, 123, 0, 1) 47%, rgba(255, 239, 104, 1) 100%);
    background: -webkit-linear-gradient(45deg, rgba(255, 0, 0, 1) 0%, rgba(255, 123, 0, 1) 47%, rgba(255, 239, 104, 1) 100%);
    background: linear-gradient(45deg, rgba(255, 0, 0, 1) 0%, rgba(255, 123, 0, 1) 47%, rgba(255, 239, 104, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ff0000', endColorstr='#ffef68', GradientType=1);
    padding: 12px;
}





#vidCdowntimer[cdown-skin="cdown-skin-default-2"] .cdown-element {
    position: relative;
    margin: 0 5px;
    background-color: rgba(0, 0, 0, 0.8);
    max-width: 80px;
    width: 100%;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-2"] #days {
    margin-left: 0;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-2"] #seconds {
    margin-right: 0;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-2"] .count {
    font-size: 38px;
    line-height: 48px;
    font-family: 'Montserrat-Regular';
}

#vidCdowntimer[cdown-skin="cdown-skin-default-2"] .text {
    padding: 4px 10px;
    background-color: rgba(0, 0, 0, 0.9);
    font-size: 10px;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-3"] .cdown-element {
    max-width: 80px;
    width: 100%;
    margin: 0 4px;
    padding: 10px;
 }

#vidCdowntimer[cdown-skin="cdown-skin-default-3"] #days {
    background-color: #e30f0f;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-3"] #hours {
    background-color: #f64b09;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-3"] #minutes {
    background-color: #dea50d;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-3"] #seconds {
    background-color: #666;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-4"] .cdown-element::after {
    top: 15px;
    color: #666
}

#vidCdowntimer[cdown-skin="cdown-skin-default-4"] .count {
    color: #e30f0f !important;
    font-size: 50px;
    line-height: 50px;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-4"] .text {
    color: #000;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;

}

#vidCdowntimer[cdown-skin="cdown-skin-default-5"] .cdown-element {
    background-color: rgba(220, 53, 69, 0.8);
    max-width: 80px;
    width: 100%;
    margin: 0 1px;
    padding: 8px;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-5"] .count {
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 40px;
    line-height: 40px;
    color: gold;
    font-family: 'Montserrat-SemiBold';
    margin-bottom: 5px;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-5"] .text {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 12px;
}

[cdown-skin="cdown-skin-default-6"] {
    padding: 12px;
    background: rgb(30, 87, 153);
    background: -moz-linear-gradient(45deg, rgba(30, 87, 153, 1) 0%, rgba(41, 137, 216, 1) 50%, rgba(125, 185, 232, 1) 100%);
    background: -webkit-linear-gradient(45deg, rgba(30, 87, 153, 1) 0%, rgba(41, 137, 216, 1) 50%, rgba(125, 185, 232, 1) 100%);
    background: linear-gradient(45deg, rgba(30, 87, 153, 1) 0%, rgba(41, 137, 216, 1) 50%, rgba(125, 185, 232, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#7db9e8', GradientType=1);
}

#vidCdowntimer[cdown-skin="cdown-skin-default-6"] .cdown-element {
    max-width: 80px;
    width: 100%;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-6"] .cdown-element::after {
    content: "";
    padding: 0;
    font-size: 24px;
    font-weight: 600;
    position: absolute;
    top: 0;
    right: -10px;
    border-right: 1px solid #ccc;
    height: 100%;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-6"] .cdown-element:nth-child(4)::after {
    display: none !important;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-6"] .count {
    margin-top: 16px;
    font-size: 32px;
    line-height: 32px;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-6"] .text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    font-size: 10px;
    text-transform: uppercase;
}

[cdown-skin="cdown-skin-default-7"] {
    padding: 12px;
    background-color: #000 !important;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-7"] .count {
    font-size: 32px;
    line-height: 32px;
    font-family: 'Monoton';
    color: #00a1c7;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-7"] .text {
    font-size: 10px;
    line-height: 10px;
    text-transform: uppercase;
    margin-top: 8px;
}

[cdown-skin="cdown-skin-default-8"] {
    background: rgb(0, 183, 234);
    background: -moz-linear-gradient(45deg, rgba(0, 183, 234, 1) 0%, rgba(0, 158, 195, 1) 100%);
    background: -webkit-linear-gradient(45deg, rgba(0, 183, 234, 1) 0%, rgba(0, 158, 195, 1) 100%);
    background: linear-gradient(45deg, rgba(0, 183, 234, 1) 0%, rgba(0, 158, 195, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00b7ea', endColorstr='#009ec3', GradientType=1);
    padding: 12px;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-8"] .count {
    font-family: 'Press Start 2P', cursive;
    font-size: 30px;
    line-height: 36px;
    color: #dc3545;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-8"] .text {
    font-family: 'Press Start 2P', cursive;
    font-size: 8px;
    text-transform: uppercase;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-9"] .count {
    color: #dc3545;
    font-family: 'Faster One', cursive;
    font-size: 42px;
    line-height: 42px;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-9"] .text {
    display: block;
    color: #000;
    text-transform: uppercase;
    font-size: 8px;
    line-height: 20px;
}

#vidCdowntimer[cdown-skin="cdown-skin-custom9"] {
    background-image: url('http://localhost/workplace/playerneos/images/playerNeos-wrapperbg.jpg');
    padding: 10px 12px 12px 12px;
}

#vidCdowntimer[cdown-skin="cdown-skin-custom9"] .count {
    font-family: 'Montserrat-Regular';
    font-size: 42px;
    line-height: 42px;
    color: #353535;
}

#vidCdowntimer[cdown-skin="cdown-skin-custom9"] .text {
    font-size: 8px;
    line-height: 8px;
    text-transform: uppercase;
    color: #000;
    font-family: 'Montserrat-SemiBold';
    letter-spacing: 2px;
}

#vidCdowntimer[cdown-skin="cdown-skin-custom9"] .cdown-element::after {
    color: #000;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-10"] {
    background-color: #000;
    padding: 6px 12px;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-10"] .cdown-element::after {
    top: 18%;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-10"] .cdown-element {
    padding: 3px;
    border: 1px solid #f00;
    display: block;
    border-radius: 100%;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-10"] .count {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: rgb(255, 0, 0);
    background: -moz-linear-gradient(45deg, rgba(255, 0, 0, 1) 0%, rgba(255, 123, 0, 1) 47%, rgba(255, 239, 104, 1) 100%);
    background: -webkit-linear-gradient(45deg, rgba(255, 0, 0, 1) 0%, rgba(255, 123, 0, 1) 47%, rgba(255, 239, 104, 1) 100%);
    background: linear-gradient(45deg, rgba(255, 0, 0, 1) 0%, rgba(255, 123, 0, 1) 47%, rgba(255, 239, 104, 1) 100%);
    background-clip: border-box;
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ff0000', endColorstr='#ffef68', GradientType=1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#vidCdowntimer[cdown-skin="cdown-skin-default-10"] .text {
    display: none;
}

#vidCdowntimer .cdown-element {
    position: relative;
    margin: 0 12px;
}

#vidCdowntimer .cdown-element::after {
    content: ":";
    padding: 0 10px;
    font-size: 24px;
    font-weight: 600;
    position: absolute;
    top: 0;
    right: -25px;
}

#vidCdowntimer #seconds::after {
    content: "";
    padding: 0;
    font-size: 24px;
}

#vidCdowntimer .cdown-element span {
    display: block;
}

#vidCdowntimer .count {
    font-size: 28px;
    line-height: 28px;
    font-family: 'Montserrat-Bold';
}




