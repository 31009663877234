#beacon-container{
    display: none !important;
}
.cc-window.cc-floating{
 display: none !important;
}

@keyframes fade-in-up {
    0% {
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
.video iframe {
    max-width: 100%;
    max-height: 100%;
}
.video.stuck {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 260px;
    height: 145px;
    transform: translateY(100%);
    animation: fade-in-up 0.25s ease forwards;
}
