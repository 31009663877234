.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.sticky {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.show-model {
    display: block !important;
    visibility: visible !important;
    opacity: 1 !important;
}


.sweet-alert {
    color: black !important;
}

.infinite-scroll-component {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.style-remove-cricle {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
}

.style-remove-cricle img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
}


.user-img .custom-file-input {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    background: orange;
    border-radius: 100%;
    cursor: pointer;
}

.user-img .custom-file {
    position: absolute;
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 65px);
    margin-bottom: 0;
    top: 0;
    left: 0;
    background: red;
    font-size: 1px;
    border-radius: 50%;
    opacity: 0;
}

.my-account-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #16d489;
}

.account-management {
    min-height: calc(100vh - 118px);
}

.hide-social {
    display: none !important;
}

#menu-show {
    left: -20px;
}

#prof_icon img {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    line-height: 45px;
    display: block;
    cursor: pointer;
    transition: .2s ease-in-out;
    overflow: hidden;
    position: relative;
    top: 7px;
}

.form-control {
    background-color: #00000000 !important;
    color: #FFFFFF;
}

.preview-zone .box {
    display: flex;
}

.clear {
    clear: both !important;
}

.modal{
    z-index: 2000 !important;
}

.show-modal {
    display: block !important;
    background: rgba(0, 0, 0, 0.5);
}


.overlaycss a {
    position: relative;
    font-size: 20px !important;
    font-weight: 600;
    text-decoration: none;
    color: #ffffff;
}

/*preview skin color*/

.vjs-matrix.video-js {
    color: #00ff00;
}

/* Change the border of the big play button. */
.vjs-matrix .vjs-big-play-button {
    border-color: #00ff00;
}

/* Change the color of various "bars". */
.vjs-matrix .vjs-volume-level,
.vjs-matrix .vjs-play-progress,
.vjs-matrix .vjs-slider-bar {
    background: #5dc766;
}


.vjs-big-play-button {
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
    width: 80px !important;
    height: 80px !important;
    -webkit-border-radius: 0.8em !important;
    -moz-border-radius: 0.8em !important;
    border-radius: 1.9em !important;
}



.video-js .vjs-big-play-button {
    background-color: #684dff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    border: 3px solid #684dff;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    height: 86px;
    width: 86px;
    cursor: pointer;
    /* z-index: 3; */
    z-index: 0;
}

.vjs-big-play-button {
    border: none !important;
    width: 80px !important;
    height: 80px !important;
    -webkit-border-radius: 0.8em !important;
    -moz-border-radius: 0.8em !important;
    border-radius: 1.9em !important;
}

.vjs-big-play-centered .vjs-big-play-button {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
 /*   margin-top: -0.75em !important;
    margin-left: -1.5em !important;*/
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    content: "" !important;
}


.animated {
    -webkit-animation-duration: 2.5s;
    animation-duration: 2.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {
    0%, 20%, 40%, 60%, 80%, 100% {
        -webkit-transform: translateY(0);
    }
    50% {
        -webkit-transform: translateY(-5px);
    }
}

@keyframes bounce {
    0%, 20%, 40%, 60%, 80%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
}

.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
}

.loader-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.video-preview {
    width: 80%;
    margin: auto;
    border-radius: 50%;
    position: relative;
}
.video {
    aspect-ratio : 16/9
}
/* 
.video-preview-portrait {
    background: #000;
    width: 100%;
    height: 140vh;
} */

.tm-5 {
    margin-top: 2.3rem !important;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
}

/* .form-control:focus {
    box-shadow: none !important;
    border: 1px solid #ced4da !important;
} */

.hide-html {
    display: none !important;
}

.sketch-picker {
    position: absolute;
    z-index: 9;
}

.swatch-cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.cursor-pointer {
    cursor: pointer;
}

.left-picker .sketch-picker {
    top: 0;
    left: 30%;
}

.color-pick {
    cursor: pointer;
}


.social-share-btns {
    position: absolute;
    top: 60px;
    right: 1%;
    display: none;
    transition: all 0.3s ease;
}

.social-share-btns ul li {
    list-style: none;
}

.videojs-menu p{
    margin-bottom: 0px;
}


.videojs-menu-btn {
    background-color: #ec3e44;
    /* position: relative;
    top: 0.5%;
    left: 0; */
    width: 100%;
    /* max-width: 120px;
    min-height: 24px;*/
    display: none; 
    margin: 0 2px;
    padding: 5px;
    text-align: center;
    cursor: pointer;
    z-index: 99;
    word-wrap: break-word;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.videojs-box {
    position: relative;
    z-index: 2;
    width: 100%;
    position: relative;
    top: 0;
    left: 60px;
    display: inline;
}
.videojs-menu{
    display: inline-block;
    width: 130px;

}
div#vidMenuContent2 {
    background: #aeaeae;
    color: #fff;
    position: absolute;
    left: auto;
    right: auto;
    top: 100px;
    height: 300px;
    width: 600px;
    margin-left: 60px;
}
.videojs-menu-btn {
    color: #fff !important;
    font-size: 100%;
    text-decoration: none !important;
}

.vid_menu_buttons {
    max-width: 320px;
    width: 100%;
    display: flex;
    text-align: left;
    position: absolute;
    top: -30px;
    left: 0;
    transition: all 0.3s ease;
}

.vidMenuContent {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #e1e1e1;
    z-index: 999;
    padding: 24px;
    overflow: hidden;
    top: 0;
    text-align: center;
    color: #212529;;
}

.vidMenuContent .vid-btn-cta {
    color: #ffffff !important;
    font-size: 14px;
    max-width: 240px;
    width: 100%;
    display: block;
    margin: 0 auto;
    padding: 12px;
    border-radius: 50px;
    text-decoration: none !important;
    transition: all 0.3s ease;
}

.menuDescription {
    margin-bottom: 10px;
}


.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}


.video-js:hover .videojs-menu-btn {
    display: block;

}

.video-js:hover .social-share-btns {
    display: block;
    transition: all 0.3s ease;

}


.overlay_clk {
    background-color: white !important;
    max-width: 380px;
    width: 100%;
    display: block;
    padding: 1em;
    border-radius: 30px;
}

.overlay_clk a {
    text-decoration: none;
    font-size: 18px;
}

.cta_heading {
    margin: 2.5rem auto 1.5rem;
}

.cta_content {
    width: 100%;
    height: 100%;
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;

}

.overlayBtn {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 22px;
    text-align: center;
}

.overlaycss {
    position: absolute;
    top: 25px;
    left: 70px;
    font-size: 22px;
    color: white;

}


/* vimeo-css */



.vjs-controls-disabled .vjs-control-bar, .vjs-using-native-controls .vjs-control-bar, .vjs-error .vjs-control-bar {
    display: flex !important;
}

/*.vjs-controls-disabled .vjs-control-bar, .vjs-using-native-controls .vjs-control-bar, .vjs-error .vjs-control-bar{
    display: flex !important;
}*/

.active-control {
    border: 2px solid #FF8F4B;
    border-radius: 10px
}

.css-1s2u09g-control {
    background: transparent !important;
    border-radius: 10px !important;
}

.css-qc6sy-singleValue {
    color: #FFFFFF !important;
}


/*
.bounce {
    animation: bounce 2s ease infinite;
}

@keyframes bounce {
    70% {
        transform: translateY(0%);
    }
    80% {
        transform: translateY(-15%);
    }
    90% {
        transform: translateY(0%);
    }
    95% {
        transform: translateY(-7%);
    }
    97% {
        transform: translateY(0%);
    }
    99% {
        transform: translateY(-3%);
    }
    100% {
        transform: translateY(0);
    }
}*/


/*CSS Button Animation */

/*@-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-30px);
    }
    60% {
        -webkit-transform: translateY(-15px);
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

.animate__bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    margin-top: 0em !important;
    margin-left: 0em !important;
}


!*Flash Animation*!

.animate__flash {
    -webkit-animation: flash 2s ease-in infinite;
    animation: flash ease-in 2s infinite;
    margin-top: 0px !important;
    margin-left: 0px !important;
}

@-webkit-keyframes flash {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes flash {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .1;
    }
    100% {
        opacity: 1;
    }
}


!* Pulse class and keyframe animation *!

@-webkit-keyframes pulse {
    0% {
        width: 200px;
    }
    50% {
        width: 340px;
    }
    100% {
        width: 200px;
    }
}

@keyframes pulse {
    0% {
        width: 200px;
    }
    50% {
        width: 340px;
    }
    100% {
        width: 200px;
    }
}

.animate__pulse {
    -webkit-animation: pulse linear .5s infinite;
    animation: pulse linear .5s infinite;
}


!*ShakeAnimation*!


@keyframes spaceboots {
    0% {
        -webkit-transform: translate(2px, 1px) rotate(0deg);
    }
    10% {
        -webkit-transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        -webkit-transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        -webkit-transform: translate(0px, 2px) rotate(0deg);
    }
    40% {
        -webkit-transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        -webkit-transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        -webkit-transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        -webkit-transform: translate(2px, 1px) rotate(-1deg);
    }
    80% {
        -webkit-transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        -webkit-transform: translate(2px, 2px) rotate(0deg);
    }
    100% {
        -webkit-transform: translate(1px, -2px) rotate(-1deg);
    }
}

@-webkit-keyframes spaceboots {
    0% {
        -webkit-transform: translate(2px, 1px) rotate(0deg);
    }
    10% {
        -webkit-transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        -webkit-transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        -webkit-transform: translate(0px, 2px) rotate(0deg);
    }
    40% {
        -webkit-transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        -webkit-transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        -webkit-transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        -webkit-transform: translate(2px, 1px) rotate(-1deg);
    }
    80% {
        -webkit-transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        -webkit-transform: translate(2px, 2px) rotate(0deg);
    }
    100% {
        -webkit-transform: translate(1px, -2px) rotate(-1deg);
    }
}

.shake {
    -webkit-animation-name: spaceboots;
    -webkit-animation-duration: 0.8s;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
}

!*HeadShake CSS*!


.animate__headShake {
    !*animation-duration: 1s infinite;
     animation-timing-function: cubic-bezier(0, .23, 1, .71);
    animation-name: headShake;*!
    transform-orgin: center;
    animation: headShake cubic-bezier(0, .23, 1, .71) 2s infinite;

}

@keyframes headShake {
    0% {
        transform: translateX(0)
    }

    12.5% {
        transform: translateX(-6px) rotateY(-9deg) skewY(1deg)
    }

    37.5% {
        transform: translateX(5px) rotateY(4.5deg) skewY(-1deg)
    }

    62.5% {
        transform: translateX(-3px) rotateY(-2.25deg) skewY(0)
    }

    87.5% {
        transform: translateX(2px) rotateY(3deg)
    }

    100% {
        transform: translateX(0)
    }
}

@-webkit-keyframes headShake {


    0% {
        transform: translateX(0)
    }

    12.5% {
        transform: translateX(-6px) rotateY(-9deg) skewY(1deg)
    }

    37.5% {
        transform: translateX(5px) rotateY(4.5deg) skewY(-1deg)
    }

    62.5% {
        transform: translateX(-3px) rotateY(-2.25deg) skewY(0)
    }

    87.5% {
        transform: translateX(2px) rotateY(3deg)
    }

    100% {
        transform: translateX(0)
    }
}


!*Swing Animation*!

.animate__swing {
    animation: swing ease-in-out 1s infinite alternate;
    transform-origin: center -20px;
    margin-top: 0.81666em !important ;
    margin-left: -1.5em !important;
}


@keyframes swing {
    0% {
        transform: rotate(5deg);
    }
    100% {
        transform: rotate(-5deg);
    }
}


!*Tada*!


@-webkit-keyframes tada {
    0% {
        -webkit-transform: scale(1);
    }
    10%, 20% {
        -webkit-transform: scale(0.9) rotate(-3deg);
    }
    30%, 50%, 70%, 90% {
        -webkit-transform: scale(1.1) rotate(3deg);
    }
    40%, 60%, 80% {
        -webkit-transform: scale(1.1) rotate(-3deg);
    }
    100% {
        -webkit-transform: scale(1) rotate(0);
    }
}

@keyframes tada {
    0% {
        transform: scale(1);
    }
    10%, 20% {
        transform: scale(0.9) rotate(-3deg);
    }
    30%, 50%, 70%, 90% {
        transform: scale(1.1) rotate(3deg);
    }
    40%, 60%, 80% {
        transform: scale(1.1) rotate(-3deg);
    }
    100% {
        transform: scale(1) rotate(0);
    }
}

.animate__tada {
    -webkit-animation-name: tada;
    animation-name: tada;
}

!*Wobble*!

@-webkit-keyframes wobble {
    0% {
        -webkit-transform: translateX(0%);
    }
    15% {
        -webkit-transform: translateX(-25%) rotate(-5deg);
    }
    30% {
        -webkit-transform: translateX(20%) rotate(3deg);
    }
    45% {
        -webkit-transform: translateX(-15%) rotate(-3deg);
    }
    60% {
        -webkit-transform: translateX(10%) rotate(2deg);
    }
    75% {
        -webkit-transform: translateX(-5%) rotate(-1deg);
    }
    100% {
        -webkit-transform: translateX(0%);
    }
}

@keyframes wobble {
    0% {
        transform: translateX(0%);
    }
    15% {
        transform: translateX(-25%) rotate(-5deg);
    }
    30% {
        transform: translateX(20%) rotate(3deg);
    }
    45% {
        transform: translateX(-15%) rotate(-3deg);
    }
    60% {
        transform: translateX(10%) rotate(2deg);
    }
    75% {
        transform: translateX(-5%) rotate(-1deg);
    }
    100% {
        transform: translateX(0%);
    }
}

.animate__wobble {
    -webkit-animation-name: wobble;
    animation-name: wobble;
}


!*Heartbeat Animation*!
.animate__heartBeat {
    animation: .8s infinite beatHeart;

}

@keyframes beatHeart {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.1);
    }
    40% {
        transform: scale(1);
    }
    60% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}


@-webkit-keyframes beatHeart {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.1);
    }
    40% {
        transform: scale(1);
    }
    60% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

!*jello*!

@keyframes jello {
    11.1% {
        transform: none
    }
    22.2% {
        transform: skewX(-12.5deg) skewY(-12.5deg)
    }
    33.3% {
        transform: skewX(6.25deg) skewY(6.25deg)
    }
    44.4% {
        transform: skewX(-3.125deg) skewY(-3.125deg)
    }
    55.5% {
        transform: skewX(1.5625deg) skewY(1.5625deg)
    }
    66.6% {
        transform: skewX(-0.78125deg) skewY(-0.78125deg)
    }
    77.7% {
        transform: skewX(0.390625deg) skewY(0.390625deg)
    }
    88.8% {
        transform: skewX(-0.1953125deg) skewY(-0.1953125deg)
    }
    100% {
        transform: none
    }
}

@-webkit-keyframes jello {
    11.1% {
        transform: none
    }
    22.2% {
        transform: skewX(-12.5deg) skewY(-12.5deg)
    }
    33.3% {
        transform: skewX(6.25deg) skewY(6.25deg)
    }
    44.4% {
        transform: skewX(-3.125deg) skewY(-3.125deg)
    }
    55.5% {
        transform: skewX(1.5625deg) skewY(1.5625deg)
    }
    66.6% {
        transform: skewX(-0.78125deg) skewY(-0.78125deg)
    }
    77.7% {
        transform: skewX(0.390625deg) skewY(0.390625deg)
    }
    88.8% {
        transform: skewX(-0.1953125deg) skewY(-0.1953125deg)
    }
    100% {
        transform: none
    }
}

.animate__jello {
    animation: jello 2s infinite;
}*/

.vjs-has-started .vjs-big-play-button {

    opacity: 0 !important;
    pointer-events: none !important;
    transition: opacity 0.5s !important;
}


.vjs-paused.vjs-has-started .vjs-big-play-button {
    display: block !important;
}

.vjs-paused .vjs-big-play-button {
    opacity: 1 !important;
    pointer-events: all !important;
    transition: opacity 0.5s !important;
    margin-top: 0em !important;
    margin-left: 0 !important;
}

.centerBtn{
    margin-top: -0.81666em !important;
    margin-left: -1.5em !important;
}

.video-js .vjs-playlist {
    display: none;
    background: rgba(0, 0, 0, .5);
    position: absolute;
    top: 0;
    right: 0;
    height: 90%;
    overflow: auto;
    transition: all 0.5s ease;

}

.video-js:hover .vjs-playlist {
    display: block;
}

.video-js .vjs-playlist-item-list {
    list-style: none;
    padding-inline-start: 0px;
}


.video-js .vjs-playlist-item {
    list-style: none;
    margin-top: 15px;
    padding: 15px 15px 15px 5px;
    width: 100%;
    border-bottom: 2px solid darkgrey;
}


.cover-gif {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}


.related-vid {
    color: white;
    margin: 0% !important;
    position: absolute;
    top: 3%;
    right: 1%;
}

.rel_vid{
    width: 50px;
    color: white;

}

.video-js .vjs-grid {
    position: absolute;
    top: 10%;
    right: 0%;
    width: 90%;
    height: 60%;
    z-index: 10;
    display: flex;
    flex-wrap: wrap;
}

.video-js .vjs-grid .rel-item {

    width: 40%;
    height: 50%;
    margin: 10px;
    box-shadow: 0px 0px 3px 3px #a0a0a094;
    border: 1px solid #c1c1c1;
}

.video-js .vjs-grid .rel-item:hover {
    border: 1px solid white;

}

.video-js .vjs-grid .rel-bg {

    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    opacity: 1;
}

/*


.vid-overlay span {
  display: flex;
  width: 100px;
  height: 100%;
  background-size: cover;
  background-position: center;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  opacity: 1;
}*/

/*
!*
.video-js .vjs-related .rel-bg {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  opacity: 1;
  display: block;
}

.video-js .vjs-grid {
    position: absolute;
    background-color: #aaaaaaa1;
    top: 10%;
    right: 0;
    width: 30%;
    height: 80%;
    z-index: 10;

    border-top: 1px solid transparent;
    overflow-x: hidden;
    overflow-y: auto;
    direction: ltr;
    text-align: left;
}

.video-js .rel-item {
    margin-bottom: 16px;
    width: 145px;
    overflow: hidden;
    background-color: #fff;
    position: relative;
    word-wrap: break-word;
    box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    color: #767676;
    text-decoration: none;
}

.video-js .rel-bg {
    position: relative;
    border-bottom: 1px solid #e2e2e2;
    width: 145px;
    height: 145px;
    background: #f1f1f1 no-repeat center;
    background-size: cover;
    color: #fff;

    height: 82px;
}

.video-js ::-webkit-scrollbar {
    width: 10px;
}

.video-js ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.507);
}

.video-js ::-webkit-scrollbar-thumb {
    background-color: #737474;
    outline: 1px solid rgb(108 108 108);
    border-radius: 10px;
}

*/


/*loader css*/
.loader::after {
    width: 10px;
    height: 10px;
    background-color: #FF8D4B;
    border-radius: 100%;
    position: absolute;
    content: "";
}

.loader {
    width: 80px;
    height: 80px;
    border: 2px #FF8D4B solid;
    border-radius: 50%;
    animation: rotation 1s ease-in-out infinite;
    text-align: center;
}

@keyframes rotation {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader-sec {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

/*.controls-button-list .bg-sec-list img.cursor-pointer.active-control {
    width: 40px;
}

img.cursor-pointer {
    width: 40px !important;
}*/

/*.picker {*/
/*    display: none !important;*/
/*}*/

.embed-code{

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}



#model_embed{
padding: 10px;
}

.fadePop {
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
}
.wrap-import{
    display: flex;
    justify-content: space-between;
}

.custom-body{
    height: 400px;
    overflow: auto;
}

.form-select:disabled{
    background-color: #151616;
}


/* VIDEO FINDER */

span.orange{
    color: #FF894A;
    font-weight: 800;
}

.videofinder h3 {
    font-size: 30px;
    font-weight: 600;
}

.list-view{
    background-color: #0C0C0C;
    padding: 12px;
    border-radius: 5px;
}

.list-view .btn-grid{
    color: #5B5B5B;
    padding:0 !important;
    margin-right: 5px;
}
.list-view .btn-grid:hover{
    color: #fff;
}

.list-view p {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0px;
    margin-right: 10px;
}

.content-box{
    padding: 25px;
}
.content-bor20{
    border-radius: 20px;
}

.content-box-bg-black{
    background-color: #000;
    overflow: hidden;
}
.content-box-bg-dark-gray{
    background-color: #000;
    overflow: hidden;
}

.videofinder .table {
    border-collapse: separate !important;
    /* border-spacing: 0; */
}

.videofinder .table thead th{
    border-top: 0px solid #707070;
    border-bottom: 0px solid #707070;
    border: 0px;
}

.videofinder th, .videofinder td{
    border:0px
}

.testme{
    background-color: red;
}


.videofinder .table td, .videofinder .table th{
    color: #fff;
    border-top: 1px solid #777;
    border-bottom: 1px solid #777;
}
.videofinder .table td{
    background: #000;
}
/* .videofinder .table>tbody{
    background-color: #161616;
} */
.videofinder .table>tbody tr{
    background-color: #707070;
    border: 1px solid #707070;
    border-radius: 15px !important;
    border-radius: 15px 0 0 0 !important;
    -moz-border-radius: 6px 0 0 0 !important;
    -webkit-border-radius: 15px ​0 0 0 !important;
    margin-bottom: 10px;
}

.videofinder .table td .dropdown .dropdown-toggle::after{
    display: none;
}

.videofinder .input-bg-black {
    background: #000 !important;
    border:1px solid #777;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: 0px !important;
    color: #fff;
}
.videofinder .input-bg-black:focus{
    border:1px solid #777 !important;
    border-left: 0px !important;
}

.search-form-keyword .input-group-text{
    background: #000 !important;
    color: #fff !important;
}

.bg-black{
    background: #000 !important;
}

.videofinder .input-bg-black::-webkit-input-placeholder { /* Edge */
    font-size: 18px;
    color: #7E7E7E;
}
  
.videofinder .input-bg-black:-ms-input-placeholder { /* Internet Explorer */
    font-size: 18px;
    color: #7E7E7E;
}
  
.videofinder .input-bg-black::placeholder {
    font-size: 18px;
    color: #7E7E7E;
}

.videofinder .search-input {
    background: #fff !important;
    border:0px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.videofinder .search-input:focus{
    border: 0px !important;
}


.videofinder .search-input::-webkit-input-placeholder { /* Edge */
    font-size: 18px;
    color: #7E7E7E;
}
  
.videofinder .search-input:-ms-input-placeholder { /* Internet Explorer */
    font-size: 18px;
    color: #7E7E7E;
}
  
.videofinder .search-input::placeholder {
    font-size: 18px;
    color: #7E7E7E;
}
.videofinder .input-group-text.white{
    background: #fff !important;
    border: 0px solid !important;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 15px 5px 15px 15px;
}

.videofinder .input-group-text.black{
    background: #000 !important;
    border: 1px solid #777 !important;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 15px 0px 15px 15px;
    border-right: 0px;
}

/* select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  } */

    #react-select-3-listbox{
        background: red !important;
    }
    /* #react-select-3-listbox ul li{
        background: yellow !important;
    } */

.videofinder .input-group-text i {
    color: #777777;
    font-size: 18px;
}
.add-filter{
    position: relative;
    background-color: #1D1D1D;
    border-radius: 8px;
}
select.add-filter{
    height: calc(2.9rem + 2px) !important;
    font-weight: 600;
    font-size: 14px;
}
select.sort-by{
    height: calc(2.9rem + 2px) !important;
    font-weight: 600;
    font-size: 14px;
}


.search-form .search {
    margin-top: 33px;
}
.list-view i {
    margin-right: 5px;
}
select#addfilter {
    color: #B5B5B5;
    height: calc(2.9rem + 2px);
    font-weight: 600;
    font-size: 16px !important;
    border-radius: 8px;
}
.add-filter:after{
    position: absolute;
    right: 12px;
    top: 15px;
    display: inline-block;
    width: .5em;
    height: .5em;
    margin: -.3em 0 0 .4em;
    vertical-align: middle;
    content: "";
    border: .3em solid;
    border-width: 0 .15em .15em 0;
    transform: rotateZ(45deg)
}
.select-custom{
    appearance: auto !important;
    position: relative;
}
.select-custom:after{
    position: absolute;
    right: 2px;
    top: 15px;
    display: inline-block;
    width: .10em;
    height: .5em;
    margin: -.3em 0 0 .4em;
    vertical-align: middle;
    content: "";
    border: .3em solid;
    border-width: 0 .15em .15em 0;
    transform: rotateZ(45deg);
}

/* REBRANDING */
.rebranding-box .dropzone-desc {
    top: 30px;
}

/* INTEGRATIONSS */

.add-account-box input.form-control {
    color: #fff;
}
.add-account input.form-control {
    color: #fff;
}

div#videoFinder label {
    font-size: 16px;
    font-weight: 600;
}

/* ANALYTICS
 */

 .date-range{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
 }

 .analytics-box{
     background: #161616;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
 }
.overHidden{
overflow: hidden;
}

 .analytics-box-row {
    background: #2D2D2D;
    border: 1px solid #777777;
    border-radius: 10px;
    padding: 10px;
    height: 108px;
}

.analytics table{
    margin-bottom: 0;
}

.analytics-box-row-text p {
    font-size: 14px;
    font-weight: 600;
}

.analytics-box-row-text .number-views {
    font-size: 28px;
    color: #E85F5F;
    font-weight: 600;
}

.analytics .table-box{
    padding: 0 !important;
    color: #fff;
}

.analytics .table thead{
    background: #000;
}
.analytics .table thead th{
    font-size: 16px;
    color: #fff;
}
.analytics .table td, .analytics .table th{
    border-top: 0px solid #dee2e6;
}

.analytics-box-row-img img{
    width: 70px;
}

.analytics .table-box th {
    vertical-align: middle;
    padding: 10px 20px;
    color: #989898;
    font-weight: 600;
    font-size: 12px;
}

.analytics .table-box td {
    padding: 10px 20px;
    color: #989898;
    vertical-align: middle;
    font-size: 12px;
}
.type-entries-box-buttons button{
    font-size: 14px;
}
.type-entries-box-buttons a{
    font-size: 14px;
}
.type-entries-box-buttons{
    margin-top: 28px;
}

.type-entries-box-buttons .btn.btn-link i {
    margin-right: 5px;
}
.type-entries-box-buttons .btn.btn-link {
    color: #FF7A46;
    text-decoration: none;
    font-weight: 600;
}
.btn-duration-submit{
    margin-top: 25px;
}

.date-range-box.d-flex{
    border: 1px solid #777777;
    padding: 10px;
    border-radius: 50px;
}
.date-range-box-text p {
    margin-left: 5px;
    margin-right: 5px;
}
.date-range-box-text p{
    padding-bottom: 0;
    margin-bottom: 0;
    font-size: 12px;
}
.date-range-box-month p{
    padding-bottom: 0;
    margin-bottom: 0;
    font-size: 10px;
    padding: 0px 10px;
}
.date-range-box-month {
    background: #777777;
    border-radius: 50px;
}
.videofinder tr td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background: #000;
    border-left: 1px solid #777;
}
.videofinder tr td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #000;
    border-right: 1px solid #777;
}

/* .videofinder tr:last-child td:first-child { border-bottom-left-radius: 10px; }
.videofinder tr:last-child td:last-child { border-bottom-right-radius: 10px; } */
/* table {
    border-spacing: 1em .5em;
    padding: 0 2em 1em 0;
    border: 1px solid orange;
  }
  
  td {
    width: 1.5em;
    height: 1.5em;
    background: #d2d2d2;
    text-align: center;
    vertical-align: middle;
  } */

  .videofinder .table-video-box{
      padding: 10px;
  }

  .videofinder .table-video-box-text{
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;          /* "auto" or "thin" */
    scrollbar-color: blue orange;   /* scroll thumb and track */
    text-align: left;
}
#videoFinder, .analytics {
    min-height: calc(100vh - 118px);
}

/* width */
.videofinder .table-video-box-text::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .videofinder .table-video-box-text::-webkit-scrollbar-track {
    background: rgb(20, 20, 20);
  }
  
  /* Handle */
  .videofinder .table-video-box-text::-webkit-scrollbar-thumb {
    background: #FF894A;
  }
  
  /* Handle on hover */
  .videofinder .table-video-box-text::-webkit-scrollbar-thumb:hover {
    background: #FF894A;
  }

  .videofinder .table-video-box-img{
    max-width: 180px;
  }

    .videofinder .dropdown button {
        background: #707070;
        padding: 4px 6px;
    }
    .videofinder .dropdown i {
        font-size: 25px;
    }


/* .videofinder tr td{
    height: 400px;
} */

/* TABLEEEEE */

.videofinder table {
    border-collapse: separate;
    border-spacing: 0 15px;
  }
  .videofinder th {
    /* background-color: #4287f5; */
    color: white;
  }
  .videofinder th,
  .videofinder td {
    width: 150px;
    text-align: left;
    /* border: 1px solid black; */
    padding: 5px;
  }
.table-box {
    background-color: #161616;
    padding: 25px;
}
.table-box th{
    font-size: 16px;
    color: #fff;
}
.table-video-box img {
    width: 100%;
    max-width: 200px;
}

.table-video-box h6 {
    font-size: 14px;
    font-weight: 600;
}
.table-video-box p {
    font-size: 12px;
    font-weight: 400;
}

.table-video-box-text {
    margin-left: 15px;
    width: 450px;
}


.table-video-box-img {
    /* background: #5E5E5E; */
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}


.table-video-box-text-buttons .btn {
    margin-right: 10px;
    font-size: 10px;
    padding: 2px 12px;
}

.table-box table {
    width: 100%;
    /* border-spacing: 0;  */
    border-collapse: separate;
}

.table td, .table th{
    vertical-align: middle;
}

/* EDITOR */
.css-1pahdxg-control{
    background-color: #303030;
}
.css-1s2u09g-control {
    background: #303030 !important;
}

.css-26l3qy-menu, .css-1pahdxg-control{
    background-color: #303030 !important;
    border-radius: 10px !important;
    background-color: #303030!important;
}

.css-1pahdxg-control:hover{
    border-color:#fff !important;
    box-shadow:0 0 0 1px #303030 !important;
    border-radius: 10px !important;
}

.css-1s2u09g-control:active, .css-1s2u09g-control:focus{
    background: #303030 !important;
}

#react-select-3-listbox{
    background-color: #303030 !important;
}

#react-select-3-listbox select::selection {
    background: #303030 !important;
}

.create-playlist-modal {
    max-width: 402px !important;
    border: 1px solid orange;
}

.rmsc .dropdown-container{
    background-color: #000 !important;
}

.rmsc .dropdown-container:focus-within{
    box-shadow:0 0 0 1px orange !important;
    border-color:orange !important;
}

.rmsc.geo-location-select .dropdown-content{
    color: #000;
}

.rmsc.geo-location-select input{
    color: #000;
}
.rmsc.geo-location-select select{
    background-color: red !important;
}

.rmsc.geo-location-select .dropdown-content select option{
    color: red;
}

.rmsc.geo-location-select .dropdown-content{
    background-color: pink !important;
}

.rmsc.geo-location-select .dropdown-content{
    background-color: #000 !important;
}


/* Test */

.multi-select {
    --rmsc-primary: #4285f4;
    --rmsc-hover: #f1f3f5;
    --rmsc-selected: #e2e6ea;
    --rmsc-border: #ccc;
    --rmsc-gray: #aaa;
    --rmsc-background: #fff;
    --rmsc-spacing: 10px;
    --rmsc-border-radius: 4px;
    --rmsc-height: 38px;
}

/* [multiselect] {
    position: relative;
    background: yellowgreen !important;
}
[multiselect]:after {
    display: none;
    background: yellowgreen !important;
}
[multiselect] select {
    display: none;
    background: yellowgreen !important;
} */
/* 
.rmsc.geo-location-select select {
    background: gold !important;
}
.rmsc.geo-location-select .form-wrapper .react-mount {
    width: 100%;
    background: brown !important;
}
.rmsc.geo-location-select .form-wrapper .react-mount input[type="checkbox"] {
    display: none;
    background: yellowgreen !important;
}
.rmsc.geo-location-select .form-wrapper .select-wrapper .checkbox-wrapper {
    max-height: 0; 
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    background: yellowgreen !important;
}
.rmsc.geo-location-select .form-wrapper .select-wrapper.open .checkbox-wrapper {
    max-height: 300px;
    overflow: auto;
    padding: 1em;
    background: yellowgreen !important;
}
.rmsc.geo-location-select .form-wrapper .select-wrapper.open .select-value:after {
    transform: translateY(-50%) rotate(180deg);
    background-color: pink !important;
}
.rmsc.geo-location-select .form-wrapper .item-label {
    color: #161414;
    font-size: 1rem;
    display: block;
    padding: 1em;
    transition: background 0.1s linear;
    background: yellowgreen !important;
    cursor: pointer;
}
.rmsc.geo-location-select .form-wrapper .item-label:hover,
.rmsc.geo-location-select .form-wrapper .item-label:active,
.rmsc.geo-location-select .form-wrapper .item-label:focus {
background-color: yellowgreen !important;
}
.rmsc.geo-location-select .form-wrapper .select-value {
    background-color: red !important;
}

.rmsc.geo-location-select .form-wrapper .select-value:after {
    content: "\f063";
    font-family: 'FontAwesome', icon;
    position: absolute;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.5s ease-in-out;
    transform-origin: 50% 50%;
    background-color: yellowgreen !important;
}

.rmsc.geo-location-select .form-wrapper .select-value .placeholder {
    font-size: 1.5rem;
    font-weight: bold;
    background-color: pink !important;
}
.rmsc.geo-location-select .form-wrapper .select-value label {
    padding: 0.5em 1em;
    background-color: pink !important;
    color: #161414;
    font-size: 3rem;
    border-radius: 5px;
    display: inline-block;
    margin: 0.25em 0.25em;
} */
.rmsc.geo-location-select label {
    background-color: #303030 !important;
    color: #fff;
    /* margin-right: 20px; */
}
.rmsc.geo-location-select label:hover {
    background-color: #1e90ff !important;
    color: #fff;
}
.rmsc.geo-location-select .select{
    background-color: #1e90ff !important;
    margin: 0 !important;
}
.rmsc.geo-location-select {
    background-color: #707070    !important;
}

.rmsc.geo-location-select input {
    background: orange !important;
    /* padding: 10px !important; */
    margin: 0 !important;
    border-radius: 0 !important;
}

.rmsc.geo-location-select form {
    background: pink !important;
}

.rmsc.geo-location-select .dropdown-content {
    background: #303030;
    /* padding: 20px; */
    /* z-index: 5;
    position: relative; */
}
.rmsc.geo-location-select .dropdown-content > div {
    background: #303030;
    border-bottom: 0px;
}

.rmsc.geo-location-select form label {
    background: red !important;
}

.rmsc.geo-location-select .checkbox {
    padding-left: 10px;
}

/* .rmsc.geo-location-select .form-wrapper .select-value label:after {
    content: 'X';
    font-size: 0.8em;
    background-color: pink !important;
}


.rmsc.geo-location-select form {
    background-color: peachpuff  !important;
}

.rmsc.geo-location-select select {
    -webkit-appearance: none;
        -moz-appearance: none;
            appearance: none;
    border: 1px solid #dddedf;
    background: #fff;
    display: inline-block;
    padding: 14px 40px 12px 16px;
    border-radius: 5px;
    width: 100%;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    background: yellow !important;
}

.rmsc.geo-location-select .form-group {
    width: 100%;
    display: flex;
    background-color: orange !important;
}

.rmsc.geo-location-select .form-group .input-group {
    flex: 1 1 50%;
    margin: 0 1em;
    position: relative;
}



.rmsc.geo-location-select .checkbox label:after {
    content: '';
    display: table;
    clear: both;
  }
  
  .rmsc.geo-location-select .checkbox .cr {
    position: relative;
    display: inline-block;
    border: 1px solid #a9a9a9;
    border-radius: .25em;
    width: 1.3em;
    height: 1.3em;
    margin-right: .5em;
  }
  
  .rmsc.geo-location-select .checkbox .cr .cr-icon {
    position: absolute;
    font-size: .8em;
    line-height: 0;
    top: 50%;
    left: 15%;
  }
  .rmsc.geo-location-select form{
      background: red;
  }
  
  .rmsc.geo-location-select input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .rmsc.geo-location-select label {
    position: relative;
    cursor: pointer;
  }
  
  .rmsc.geo-location-select label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #0079bf;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }
  
  .rmsc.geo-location-select input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  .rmsc.geo-location-select input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .upgrades-sec-head .nav-pills{
      margin-top: 34px;
  }

  .upgrade-para a > button {
    font-size: 12px !important;
    } */

  /* .form-group input:checked , .form-group  label:after {
    content: '';
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
    width: 6px;
    height: 14px;
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  } */

/* TOOLTIPP */
/* a {
    font-size:25px;
    color: #0bd;
    text-decoration: none;
  } */
  /* .my_tooltip {
    display: inline;
    position: relative;
  }
  .my_tooltip:hover:after{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    background: #444;
    border-radius: 8px;
    color: #fff;
    content: attr(title);
    margin: -82px auto 0;
    font-size: 16px;
    padding: 13px;
    width: 220px;
  }
  .my_tooltip:hover:before{
    border: solid;
    border-color: #444 transparent;
    border-width: 12px 6px 0 6px;
    content: "";
    left: 45%;
    bottom: 30px;
    position: absolute;
  } */

  .my_tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 0px dotted black;
  }
  
  .my_tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: black;
    border: 1px solid #fff;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    top: -35px;
    position: absolute;
    z-index: 1;
    white-space: pre;
    font-size: 12px;
}

.my_tooltip i {
    color: #757575;
    margin-left: 5px;
}
  
  .my_tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .img-thumbnail{
    padding: 0;
    border: 2px solid #dee2e6;
  }

  .create-player .form-check-input{
    margin-left: -1.5em;
  }

  .videojs-row {
    /* background: red; */
    position: absolute;
    /* top: 0; */
    height: 100%;
    width: 100%;
    display: block;
}


  /* custom input */
  .upgrades-sec .thumbnail-form .form-group .custom-file-input{
    height: calc(7.2rem + 2px);
    top: -82px;
    cursor: pointer;
  }

  .upgrade-box-content a .btn {
    font-size: 14px;
}

  .upgrades-sec .thumbnail-form .form-group{
      margin-bottom: 0px;
  }

  .pick-video .form-control{
    position: relative;
    display: inline-block;
    margin-top: 20px;
  }
  .pick-video .form-controla[title]:hover:after { background: #8b6ab2; }

    /* .pick-video {
        background: red;
    }

    .pick-video a[data-title]:hover:after{
        content: attr(data-title);
        background: red; 
        position: absolute;
        left: 5%;
        top: 10%;
    } */

    
/* IFRAMEEE */

.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } 
.embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }


.video-js .vjs-big-play-button span{
    text-align: center;
    color: rgb(236, 62, 68);
    position: absolute;
    inset: 95% 50% 0%;
    transform: translate(-50%, -50%);
    display: inline-block;
    width: 350px;
    overflow-wrap: break-word;

}

.video-js .vjs-play-progress:before {
    font-size: 1.2em !important;

}

.video-js .vjs-progress-holder{
    height: 0.5em !important;
}

.videoprojects-modal {
    /* display: block; */
    /* flex-shrink: 0; */
    width: fit-content;
    max-width: initial;
    padding-right: 0;
    padding-left: 0;
    margin-top: 20px;
}

.sketch-picker{
    left: 20px;
}

#embed .modal{
    z-index: 1 !important;
}

/*

.pagination{
    background-color: #212529;
}

.page-link{
    color: #989898;
    background-color: #212529;
}

*/

.pagination {
    margin: 0;
    padding: 10px;
    display: flex;
    list-style: none;
    outline: none;
    height: 100%;
}
.pagination > .active > a{
    background-color: #47ccde ;
    border-color: #47ccde ;
    color: #fff;
}
.pagination > li > a{
    border: 1px solid #414141 ;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
    text-transform: capitalize;
    text-decoration: none;
}

.pagination > li > a:hover{
    background-color: #ff6161 ;
    border: 1px solid #ff6161 ;
    outline: none ;
    color: #fff;
    text-decoration: none;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #ff6161 ;
    border: 1px solid #ff6161 ;
    outline: none ;
    color: #fff;
    text-decoration: none;
}
.pagination > li > a, .pagination > li > span{
    color: #fff
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset
}

.ytp-chrome-top, .ytp-chrome-bottom{
 display: none !important;
}

.tos a{
     text-decoration: none;
}

.tos a:hover{
    color: #FF6161;

}

.cursor-disabled{
    cursor: not-allowed;
}

.nav-item div{
    /* padding-top: 3px; */
    margin-right: 30px;
}

.nav-item button::after{
    position: absolute !important;
    right: 0 !important;
    top: 17px !important;
    bottom: 0 !important;
    display: none;
}

 #adTitle{
    display: none;
    position: absolute;
    bottom: 30px;
    left: 0;
    transition: all 0.3s ease;
    background-color: rgba(0,0,0,.5);
    padding: 5px;
}

#adSkip{
    display: none;
    position: absolute;
    bottom: 30px;
    right: 0;
    transition: all 0.3s ease;
    background-color: rgba(0,0,0,.5);
    padding: 5px;
    cursor: pointer;
}

#adTitle h4, #adSkip h4{
    font-size: 14px !important;

}


/* queenie cssssss */

input[type="date"]::-webkit-calendar-picker-indicator {
    color: #fff;
    opacity: 1;
    display: block;
    filter: invert(1);
    /* background: url(https://mywildalberta.ca/images/GFX-MWA-Parks-Reservations.png) no-repeat; */
    width: 20px;
    height: 20px;
    border-width: thin;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    color: #fff;
    opacity: 1;
    display: block;
    filter: invert(1);
    width: 20px;
    height: 20px;
    border-width: thin;
}
/* 
*:focus {
    outline: none;
    border: 1px solid #fff;
} */

textarea:focus, input:focus{
    outline: none;
    border: 1px solid #fff !important;
}

.video-preview-portrait {
    /* max-height: 520px !important; */
    margin: 0 auto;
    overflow: hidden;
    /* max-width: 350px; */
}

.bg-sec-box .form-group {
    margin-bottom: 0;
}

#featured-video {
    max-height: 520px !important;
}

/* Custom File Hover CSS */
.tooltip-cus {  
            display: inline;  
            position: relative;  
            text-decoration: none;  
            top: 0px;  
            /* left: 4px;   */
            font-size: 12px;
        }  
        .tooltip-cus:hover:after  
        {  
            background: #000;  
            border: 1px solid #fff;
            border-radius: 5px;  
            top: -5px;  
            color: #fff;  
            content: attr(alt);  
            left: 222px;  
            padding: 5px 15px;  
            position: absolute;  
            z-index: 98;  
            width: max-content;  
        }  
        .tooltip-cus:hover:before  
        {  
            /* border: 1px solid #fff;  */
            background: #000;   
            /* border-width: 6px 6px 6px 0;   */
            bottom: 20px;  
            content: "";  
            left: 155px;  
            position: absolute;  
            z-index: 99;  
            top: 3px;  
        }
/* .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: 'Select some files';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid pink;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }
  .custom-file-input:hover::before {
    border-color: black;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  } */

  .btn.btn-remove .remove-preview{
    font-size: 14px;
    font-weight: 500;
    color: #6E6E6E;
    padding: 0;
  }


  .btn.btn-remove > label{
      margin-bottom: 0px !important;
  }

.video-js #position{
    left: 47% !important;
    top: 45% !important;
    transform: translate(-50%, -50%);

}

.active-side{
    background: linear-gradient(90deg, #FF8D4B, #FF4139) !important;
}

.active-help{
    color: #FF8D4B !important;
}



/* body{ padding-top: 60px;} */
.help-support-banner{ margin-top: 0;}
.profile-sec{ padding-top: 60px;}
.gdpr-sec{ padding-top: 1px;}

.footer .footer-menu ul li a:hover{ color:blue;}
.footer-copyright a{ color:#00F5A0;}
.integration-box li:hover{ background: linear-gradient( 180deg ,#FF8F4B,#FF3D38); box-shadow: 0 0 20px 0 #FF8F4B, 0 0 20px 0 #FF3D38; transform: scale(1.1) !important;}
.user-img .custom-file{ z-index: 999;}
.imgEdit{ position: absolute; width: 100%; height: 100%; left: 0; top: 0; display: flex; align-items: center; justify-content: center; background: rgba(0,0,0,0.4); border-radius: 100%; opacity: 0; visibility: hidden; transition: all 0.5s ease;}
.user-img:hover .imgEdit{ opacity: 1; visibility: visible;}
.imgsizeright{ margin-left: -230px;}
.user-img{ margin-bottom: 10px;}

footer .footer-menu li a{ font-size: 16px;}
footer .footer-menu li a:hover{ color:blue !important;}
footer p{ font-size: 16px !important;}

.imgEdit img{ width: auto !important; height: auto !important;}

.services img{ width: 100% !important;}
.laptop-img{ margin-left: 0 !important;}
.laptop-img-2{ margin-left: 0 !important;}
.services:nth-child(odd) [class*='col-']{ order: 2;}
.services:nth-child(odd) [class*='col-'] + [class*='col-']{ order: 1;}

.personal-details-box-form select.form-control{ border: none; padding: 0; height: 21px;}
.upgrades-sec-head .nav-item button:hover::before{ display: none;}
.upgrades-sec-content{ min-height: calc(100vh - 300px);}

p.copyright{ width: 100% !important;}
p.copyright a{ color: #EE3F45 !important;}
.footer-mid-light p{ color: #FFF !important;}


.commonModal .modal-content{ background: none !important; border: none !important;}
.commonModal .modal-body{ background: #000; overflow: hidden; border-radius: 20px; padding: 25px;}
.commonModal .embed-responsive{ width: 100%; height: 0; padding-bottom: 56%; position: relative; aspect-ratio: 16 / 9;}
.commonModal .embed-responsive iframe{ width: 100%; height: 100%; position: absolute; display: block; top: 0; left: 0;}
.commonModal .modal-dialog{ max-width: 1130px !important;}
.commonModal .close{ position: absolute; right: 0; top: -42px; opacity: 1 !important;}
.modal{ display: block !important; opacity: 0; visibility: hidden; transition: all 0.5s ease;}
.modal.show-modal{ opacity: 1; visibility: visible;}
.commonModal .modal-dialog{ transform: scale(0); transition: all 0.5s ease;}
.commonModal.show-modal .modal-dialog{ transform: scale(1);}

.logoimg{ margin-top: -60px;}
.upgrads-sec{ padding-top: 50px; min-height: calc(100vh - 110px);}
.upgrade-content{min-height: 10px;}
.project-banner{ padding-top: 50px;}

.video-preview{
    padding-top: 56.25%;
    height: 0px;
    position: relative;}
.video-preview-portrait{ width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;}

.container{ max-width: 1500px;}

#featured-video{ overflow: hidden !important;}

@media screen and (max-height: 800px) {
  .commonModal .modal-dialog{ max-width: 940px !important;}
}


@media (max-width: 1699px) {
  .imgsizeleft{ margin-left: 0;}
  .imgsizeleft img{ max-width: 100%;}
  .imgsizeright{ margin-left: 0;}
  .imgsizeright img{ max-width: 100%;}
  .banner-content h1{ font-size: 60px;}
  .banner-content h4{ font-size: 30px;}

  .container{ max-width: 1200px;}
  .project-button-create.altx .gradiant-button-pb{ padding-left: 18px; padding-right: 18px;}
  .project-button-create.altx .create-playlist-btn{ margin: 0 8px !important; font-size: 14px;}
}

@media (max-width: 1199px) {
  .banner-content h1{ font-size: 45px;}
  .banner-content h4{ font-size: 25px;}
  .banner-content p{ font-size: 15px;}
  .banner-video-project .video-project h5{ font-size: 35px;}
  .dashborad-sec-3-content h4{ font-size: 25px;}
  .dashborad-sec-3-content h6{ font-size: 16px;}
  .dashborad-sec-3-content p{ font-size: 15px;}
  .webiner-content p{ font-size: 15px;}
  .webiner-content h3{ font-size: 25px;}
  .footer .footer-copyright p{ font-size: 13px;}
  .footer .footer-menu ul li a{ font-size: 13px;}

  .integration-sec{ padding-top: 60px;}
  .help-banner-content h3{ font-size: 25px;}
  .secarchicone-part #searchimg{ left: 0;}
  .popular-topics-box .nav .nav-link{ padding-left: 20px;}

  .navbar-light .navbar-nav .nav-link{ margin-right: 10px;}
  footer p{ font-size: 14px !important;}
  footer .footer-menu li a{ font-size: 14px;}

  .my-video-projects-header.mt-3 [class*='col-']{ flex: 0 0 100%; max-width: 100%;}
  .project-header.d-sm-flex.d-block{ align-items: center !important; justify-content: center !important; flex: 0 0 100%; max-width: 100%; display: flex !important;}
  .col-12.col-lg-7.d-flex.justify-content-lg-end.justify-content-center{ justify-content: center !important;}

  .footer-mid-light [class*='col-']{ flex: 0 0 100%; max-width: 100%;}
  .sticky .navbar{ padding-left: 15px; padding-right: 15px;}
}

@media (max-width: 1023px) {
  .footer-copyright{ padding: 8px 0;}
  .navbar-collapse{ position: fixed; left: 0; top: 71px; width: 100%; height: 100%; background: #000; padding: 30px; padding-top: 0;}
  header .navbar ul li{ margin-right: 0;}
  header .navbar .navbar-nav .nav-link{ padding-top: 15px; padding-bottom: 15px; border-bottom: 1px solid rgba(255,255,255,0.2);}
  header .dropdown button{ padding: 15px 0;}
  header .dropdown-menu{ position: static !important; width: 100%;}

  .traning-sec .col-md-4, .traning-sec .col-md-8{ width: 100%;}
  .popular-topics-content{ min-height: 10px; margin-top: 30px;}
}

@media (max-width: 767px) {
  .banner-content h1{ font-size: 35px;}
  .banner-content h4{ font-size: 20px;}
  .banner-items{ padding: 0 0 30px 0 !important;}
  .banner-content{ padding: 20px 0 !important; text-align: center !important;}

  .help-support-banner{ padding-top: 60px;}
  .project-button-create.altx .create-playlist-btn{ margin-bottom: 8px !important;}
}